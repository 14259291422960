import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { MEDIA_QUERIES } from '../../configs/media-queries.config';

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  isLargeDesktop$ = fromEvent(window, 'resize').pipe(
    startWith(true),
    map(() => window.matchMedia(`(min-width: ${MEDIA_QUERIES.MIN_LARGE_DESKTOP})`).matches),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  isMediumDesktop$ = fromEvent(window, 'resize').pipe(
    startWith(true),
    map(() => window.matchMedia(`(min-width: ${MEDIA_QUERIES.MIN_MEDIUM_DESKTOP})`).matches),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  isDesktop$ = fromEvent(window, 'resize').pipe(
    startWith(true),
    map(() => window.matchMedia(`(min-width: ${MEDIA_QUERIES.MIN_DESKTOP})`).matches),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  isLargeTablet$ = fromEvent(window, 'resize').pipe(
    startWith(true),
    map(() => window.matchMedia(`(min-width: ${MEDIA_QUERIES.MIN_LARGE_TABLET})`).matches),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  isTablet$ = fromEvent(window, 'resize').pipe(
    startWith(true),
    map(() => window.matchMedia(`(min-width: ${MEDIA_QUERIES.MIN_TABLET})`).matches),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  isMobile$ = fromEvent(window, 'resize').pipe(
    startWith(true),
    map(() => window.matchMedia(`(min-width: ${MEDIA_QUERIES.MIN_MOBILE})`).matches),
    shareReplay({ bufferSize: 1, refCount: true })
  );
}
