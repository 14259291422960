import { createAction, props } from '@ngrx/store';
import { LayoutState } from '../reducers/layout.reducer';

export enum LayoutActionTypes {
  Init = '[SEE Form] Init layout',
  Update = '[SEE Form] Update layout',
}

export const LayoutInitAction = createAction(LayoutActionTypes.Init);

export const LayoutUpdateAction = createAction(LayoutActionTypes.Update, props<{ payload: LayoutState }>());
